//CURENT DATE TIME FUNCTION
function ShowDate()
{
	var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

	var dNow = new Date();
	var localdate= (days[dNow.getDay()] + ', ' + months[dNow.getMonth()] + ', ' + dNow.getDate() + ', ' + dNow.getFullYear());

	$('#currentDate').html(localdate)
}

//SIDE POPOVER
$('.showpage').click(function(){
	//$('.case-searchmain').removeClass('open-canvas');
	var getId = $(this).attr('href');
//	$('.canvas-overlay').fadeIn();
	$(getId).addClass('open-canvas');
});

$('.closebtn').click(function () {
	$(this).parents(".popopslide-main").removeClass('open-canvas');
//	$('.canvas-overlay').fadeOut();
});

// accordians start
$(document).ready(function(){
	// Add minus icon for collapse element which is open by default
  $(".collapse.show").each(function () {
	   $(this).prev(".card-header").find(".far").addClass("fa-minus-square").removeClass("fa-plus-square");
	   
	});
  
	// Toggle plus minus icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function () {
    //alert("clicked sub1");
	   $(this).prev(".card-header").find(".far").removeClass("fa-plus-square").addClass("fa-minus-square");
	   $(this).prev(".card-header").find(".scButtonsrow").addClass("scButtons");
  }).on('hide.bs.collapse', function () {
    //alert("clicked sub2");
	   $(this).prev(".card-header").find(".far").removeClass("fa-minus-square").addClass("fa-plus-square");
	   $(this).prev(".card-header").find(".scButtonsrow").removeClass("scButtons");
	});
});
// accordians end


// accordians start
$(document).ready(function () {
  // Add minus icon for collapse element which is open by default
  $(".collapse.show").each(function () {
    $(this).prev(".card-header").find(".far").addClass("fa-minus-square").removeClass("fa-plus-square");

  });

  // Toggle plus minus icon on show hide of collapse element
  //$(".collapse").find('show.bs.collapse', function () {
  //  $(this).prev(".card-header").find(".far").removeClass("fa-plus-square").addClass("fa-minus-square");
  //  $(this).prev(".card-header").find(".scButtonsrow").addClass("scButtons");
  //}).on('hide.bs.collapse', function () {
  //  $(this).prev(".card-header").find(".far").removeClass("fa-minus-square").addClass("fa-plus-square");
  //  $(this).prev(".card-header").find(".scButtonsrow").removeClass("scButtons");
  //});

  //$(".collapse").find()

});


//REGISTAR DESIGN SEARCH NETWORK SHOW HIDE GRID	
$("#searchNTWK1").click(function(){
	$(".searchntwk1").show();
});
$("#searchNTWK2").click(function(){
	$(".searchntwk2").show();
});

$("#slectdfaclties1").click(function(){
	$(".slectdfaclties1").show();
});
$("#slectdfaclties2").click(function(){
	$(".slectdfaclties2").show();
});
// accordians end
// home accordians start
$(document).ready(function(){
	// Add minus icon for collapse element which is open by default
	$(".collapse-alerts.show").each(function(){
	   $(this).prev(".card-header").find(".fas").addClass("fa-chevron-up").removeClass("fa-chevron-down");
	});
	
	// Toggle plus minus icon on show hide of collapse element
	$(".collapse-alerts").on('show.bs.collapse', function(){
	   $(this).prev(".card-header").find(".fas").removeClass("fa-chevron-down").addClass("fa-chevron-up");
	}).on('hide.bs.collapse', function(){
	   $(this).prev(".card-header").find(".fas").removeClass("fa-chevron-up").addClass("fa-chevron-down");
	});
	
	
	//SECOND MODEL MISSING BACKDROP
	$(document).on('show.bs.modal', '.modal', function (event) {
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            $(this).css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);
        });
	
});
// accordians end

//RADIO BUTTONS ON CHECK SHOW HIDE DIVES
$(function() {
   $("input[name='searchcreatsites']").click(function() {
     if ($("#search-site").is(":checked")) {
       $("#serchExsites").show();
	   $("#crtNew-sites").hide();
     }
	 else{
       $("#crtNew-sites").show();
	   $("#serchExsites").hide();
     } 
	 
   });
 });
 $(function() {
	 $("#add-branch").hide();
   $("input[name='create-add-network']").click(function() {
     if ($("#crt_newntwk").is(":checked")) {
       $("#crt-newnetwork").show();
	   $("#add-branch").hide();
     }
	 else{
       $("#add-branch").show();
	   $("#crt-newnetwork").hide();
     } 
	 
   });
 });
 
$(document).ready(function(){
	$("#showupl-field").click(function(){
		if($(".updatepfl-hidefield").show()){
			$(".updatepfl-hidefield").hide();
			$(".updatepfl-showfield").show();
		}
		else if($(".updatepfl-hidefield").hide()){
			$(".updatepfl-hidefield").show();
			$(".updatepfl-showfield").hide();
		}
	});
});


//MEDICAL INFORMATIO PORTAL JS
//REGISTAR DESIGN SEARCH NETWORK SHOW HIDE GRID	
$("#hlthBtn-show").click(function(){
	$(".healthcareradio-btn").show();
});
$("#hlthBtn-hide").click(function(){
	$(".healthcareradio-btn").hide();
});

//Chat window Function	
	$(".chaicon,.chattbtn").click(function(){
		$(".chatwindow").slideDown('300');
	});
	$(".closepop").click(function(){
		$(".chatwindow").hide('300');
	});

// find mediacal info start
$(".hideable").hide();
$("#search").click(function(){
	$(".feedbck-section").show();
});

//READ MORE FUNCTION START
// $('.colapse').readmore({
// 	speed: 500,
// 	moreLink: '<div class="d-flex justify-content-end"><a href="#" class="readbtn">More...</a></div>',
// 	lessLink: '<div class="d-flex justify-content-end"><a href="#" class="readbtn">Less...</a></div>',
// 	collapsedHeight: 68 });
//READ MORE FUNCTION END

$('.prgressaccess-tabs li').click(function(){
    $('.prgressaccess-tabs li').removeClass("active");
    $(this).addClass("active");
});


// Read more Less start
var moretext = "More";
var lesstext = "Less";
var ellipsestext = "...";
var showChar = 270;
$('.more').each(function() {
  var content = $(this).html();

  if(content.length > showChar) {

      var c = content.substr(0, showChar);
      var h = content.substr(showChar, content.length - showChar);

      var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

      $(this).html(html);
  }

});

$(".morelink").click(function(){
  if($(this).hasClass("less")) {
      $(this).removeClass("less");
      $(this).html(moretext);
  } else {
      $(this).addClass("less");
      $(this).html(lesstext);
  }
  $(this).parent().prev().toggle();
  $(this).prev().toggle();
  return false;
});
// Read more Less end
// notification read more less start
$('.swText').click(function(){

	var parentnow = $(this).parent('.mytask-det');
    
	if($(this).is(':visible') && $(parentnow).hasClass('showtext')){
		$(parentnow).removeClass('showtext');
		//$(this).text("Read More...");	
	}
	else {
		$(parentnow).addClass('showtext');
		//$(this).text("Read Less...");		
	}
	return false;
});
// notification read more less end

$(".covid-label .fas").click(function(){
	$(".covid-label").hide();
  });


  
$(document).ready(function(){
	// Prevent closing from click inside dropdown
	// $(document).on('click', '.dropdown-menu', function (e) {
	// 	e.stopPropagation();
	//   });
	  console.log("Log working!!");
	  // make it as accordion for smaller screens
	  if ($(window).width() < 992) {
		$('.dropdown-menu a.dropdown-toggle').click(function(e){
		//   e.preventDefault();
		e.stopPropagation();
		e.preventDefault();
			if($(this).next('.submenu').length){
			  if($(this).next('.submenu').hasClass('sub-menu-visible')){
				$(this).next('.submenu').removeClass('sub-menu-visible');
			  }
			  else {
				$(this).next('.submenu').addClass('sub-menu-visible');
			  }
			}
			$('.dropdown').on('hide.bs.dropdown', function () {
		   $(this).find('.submenu').hide();
		})
		});
	  }
	});




(function($) {
    $.fn.hasScrollBar = function() {
        return this.get(0).scrollHeight > this.height();
    }
})(jQuery);

if($('.pageContentMain').hasScrollBar()){
	$('.pageContentMain footer').removeClass('footer-fixed');
}
else {
	$('.pageContentMain footer').addClass('footer-fixed');
}

// Cookies Selection
function cookieSettings(){
    $(".ckSelections").show();
}
function closeCookie(){
    $(".privacyPrompt").hide();
}
// Our Products
$("#neuroList").click(function(){
    $(".neuroList-content").toggle();
});
$("#oncologyList").click(function(){
    $(".oncologyList-content").toggle();
});
$("#urologyList").click(function(){
    $(".urologyList-content").toggle();
});
$('.ourproductHead').click(function(){
    $(this).find(".fa-angle-down").toggleClass('rotateicn');
});
$('.medicalinfo-nav a').click(function(){
    $(this).find(".fa-chevron-down").toggleClass('rotateIcon');
});

// OWL Carowsel
$(document).ready(function() {
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 1,
          nav: false
        },
        1000: {
          items: 1,
          nav: true,
          loop: false,
          margin: 20
        }
      }
    })
  });

// Search Information List View And Grid View
function lstView(){
    if(".DocgridView:hidden"){
        $('.DocgridView').show();
        $('.documentListview').hide();
    }
    else{
        $('.DocgridView').hide();
        $('.documentListview').show();
    }
}
function gdView(){
    if(".documentListview:hidden"){
        $('.documentListview').show();
        $('.DocgridView').hide();
    }
    else{
        $('.DocgridView').show();
        $('.documentListview').hide();
    }
}
